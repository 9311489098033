<template>
  <div class="footer">
    <div class="container">
      <h2>艺光国际文化传媒（北京）有限公司</h2>
      <div class="f-r">
        <p>公司电话：18510664759 | 公司邮箱：740704251@qq.com</p>
        <p>公司地址： 北京市朝阳区广顺北大街33号院1号楼10层1单元1101室9151号</p>
        <p>
          版权所有：艺光国际文化传媒（北京）有限公司
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >备案号：京ICP备2021005222号-2</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-footer",

  props: {
    email: String,
    phone: String,
    icp: String,
    name: String,
    addr: String,
  },
  methods: {
    // 跳转最新文章
    goAc1() {
      this.$router.push("/ac1");
    },
    // 跳转行业资讯
    goAc2() {
      this.$router.push("/ac2");
    },
    // 跳转独加新闻
    goAc3() {
      this.$router.push("/ac3");
    },
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    goHome() {
      this.$router.push("/index");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.footer {
  // height: 90px;
  width: 100%;
  box-shadow: inset 0px 3px 2px 0px #ccc;
  // background: #21272f;
  .container {
    height: 100%;
    text-align: center;
    padding-top: 10px;
    box-sizing: border-box;
    //  display: flex;
    //  align-items: center;
    @include flex();
    h2 {
      font-size: 21px;
      font-weight: 400;
      color: #333;
    }
    .f-r {
      p {
        font-size: 14px;
        color: #666;
        margin: 10px 0;
        a {
          color: #666;
        }
      }
    }
  }
}
</style>
