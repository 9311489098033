<template>
  <div class="index">
    <!-- banner 区块 -->
    <div class="banner">
      <img src="/imgs/b1.jpg" alt="" />
    </div>

    <!-- 顶宽布局 -->
    <div class="container">
      <!-- 关于我们 -->
      <div class="us">
        <!-- 标题 -->
        <div class="us-title">
          <h4>ABOUT US</h4>
          <h2>关于我们</h2>
        </div>

        <!-- 内容 -->
        <div class="us-con">
          <!-- 左侧 -->
          <div class="us-l">
            <h2>艺光国际文化传媒（北京）有限公司</h2>
            <p>
              艺光国际文化传媒(北京)有限公司成立于2017年06月13日，经营范围包括组织文化艺术交流活动（不含演出）；教育咨询（不含出国留学咨询及中介服务）；企业策划；企业管理咨询；文艺创作；承办展览展示活动；销售乐器；租赁机械设备、乐器；互联网信息服务；经营电信业务；出版物零售；广播电视节目制作；人力资源服务；文艺表演等...
            </p>
          </div>
          <!-- 右侧 -->
          <div class="us-r">
            <img src="/imgs/us.jpg" alt="" />
          </div>
        </div>
      </div>

      <!-- 咨询区块1 -->
      <div class="a1-con">
        <!-- 标题 -->
        <div class="a-title">
          <h4>Latest news</h4>
          <h2>最新资讯</h2>
        </div>

        <!-- 内容 -->
        <div class="a-con">
          <!-- 左侧 -->
          <div class="a-r">
            <!-- 循环项 -->
            <div
              class="a-item"
              @click="gopay()"
              v-for="(item, index) in this.ac1"
              :key="index"
            >
              <span>{{ item.title }}</span>
              <span>付费阅读</span>
            </div>
          </div>
          <!-- 右侧 -->
          <div class="a-l">
            <!-- 循环项 -->
            <div class="l-item"  @click="gopay()">
              <div class="title">
                互联网VR运动提出可变动态解决方案
              </div>
              <p>
                互联网显示器中的像素可能无法瞬间发生改变，而是需要一段时间进行转换。在这段时间里，像素的外观可能没有忠实地匹配指定颜色。为了尝试减少运动模糊，传统显示器会在像素转换期间…
              </p>
            </div>
            <div class="l-item" @click="gopay()">
              <div class="title" >
                互联网时代的工伤，越来越隐蔽
              </div>
              <p>
                与传统的职业健康风险相比，当代新型经济环境下，工作给人带来的伤害，正变得越来越隐蔽、难以界定。集中在某些特定行业。制造业里的一些特定行业经常带来身体伤害，而且伤害的严重程度比较突出，比如五金、家电...
              </p>
            </div>
            <div class="l-item"  @click="gopay()">
              <div class="title">
                再被四部门约谈，回顾蚂蚁273天里的多轮风波
              </div>
              <p>
                据央行官网消息，2021年4月12日，人民银行、银保监会、证监会、外汇局等金融管理部门再次联合约谈蚂蚁集团...
              </p>
            </div>
            <div class="l-item" @click="gopay()">
              <div class="title" >
                要革命互联网的IPFS，如何加速落地？
              </div>
              <p>
                IPFS如何打破中心化存储走向分布式？简单来说，在IPFS网络中，文件会被特殊加密的算法打散成若干个文件碎片，分散存储到世界各地的IPFS网络节点中...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="margin: 0 auto" src="/imgs/pay.png" />
        <h2>微信支付：2元</h2>
       
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal";
// import at1 from "./../components/at1";
export default {
  name: "index",
  components: {
    Modal,
    // at1
  },
  data() {
    return {
      href: "index.html",
      state: JSON.parse(window.localStorage.getItem("state")),
      showModal: false,
      ac1: [],
      ac2: [],
      ac3: [],
      ac4: [],
      ac5: [],
      myData: [],
    };
  },
  mounted() {
    this.init1();
    document.title = "艺光国际文化传媒（北京）有限公司";
  },
  methods: {
    init1() {
      // this.axios.get("/mock/ac1.json").then((res) => {
      //   const data = res.data;
      //   // 咨询区块1
      //   this.ac1 = data.slice(0, 4);
      //   this.ac2 = data.slice(4, 8);
      //   this.ac3 = data.slice(4, 7);
      //   this.ac4 = data.slice(10, 25);
      //   this.ac5 = data.slice(6, 10);
      // });
      this.axios.get("/mock/index.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.ac1 = data.splice(0, 12);
        this.myData = data.splice(0, 6);
      });
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      // this.showModal = true;
      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条2元，点击确定登录购买");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  // banenr 区块
  .banner {
    width: 100%;
    height: 450px;
    // background-color: pink;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  // 定宽布局
  .container {
    //资讯1
    .a1-con {
      // 标题
      .a-title {
        border-bottom: 2px dashed #ccc;
        width: 100%;
        h4 {
          font-size: 18px;
          color: $colorZ;
        }
        h2 {
          font-size: 28px;
          font-weight: 400;
          margin-bottom: 15px;
        }
      }

      // 内容
      .a-con {
        width: 100%;
        // height: 200px;
        @include flex();
        margin-top: 15px;
        .a-l {
          width: 40%;
          height: 100%;
          cursor: pointer;
          margin: 15px auto;

          // 循环项
          .l-item {
            border-bottom: 1px dashed #ccc;
            &:hover {
              background: #f5f5f5;
            }
            &:nth-child(2) {
              text-align: right;
              .title {
                text-align: right;
              }
            }

            &:nth-child(4) {
              text-align: right;
              .title {
                text-align: right;
              }
            }
            .title {
              font-size: 18px;
              color: #7c7c7c;
              font-weight: 400;
              margin: 10px 0;
              text-align: left;
            }
            p {
              color: #7c7c7c;
              font-size: 14px;
              line-height: 30px;
            }
          }
        }
        .a-r {
          width: 50%;
          height: 100%;
          // background-color: #c60023;
          margin-left: 25px;
          .a-item {
            width: 100%;
            height: 30px;
            @include flex();
            margin: 10px 0;
            &:hover span {
              color: $colorZ;
            }
            span {
              font-size: 14px;
              color: #333;
              cursor: pointer;
              transition: all 0.3s;
            }
          }
        }
      }

      // 按钮
      .a-btn {
        display: inline-block;
        width: 80px;
        height: 30px;
        border: 1px solid $colorZ;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        transition: all 0.3s;
        margin-left: 42%;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover {
          background-color: $colorZ;
          color: #fff;
        }
      }
    }

    // 关于我们
    .us {
      margin: 60px auto;
      // 标题
      .us-title {
        border-bottom: 2px dashed #ccc;
        width: 100%;
        h4 {
          font-size: 18px;
          color: $colorZ;
        }
        h2 {
          font-size: 28px;
          font-weight: 400;
          margin-bottom: 15px;
        }
      }
      // 容器
      .us-con {
        width: 100%;
        height: 300px;
        padding-top: 10px;
        box-sizing: border-box;
        @include flex();
        .us-l {
          width: 50%;
          height: 100%;
          padding: 30px;
          box-sizing: border-box;
          h2 {
            font-size: 21px;
            color: #333;
            font-weight: 400;
          }
          p {
            font-size: 12px;
            color: #333;
            line-height: 60px;
          }
        }
        .us-r {
          width: 40%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
