<template>
  <div class="nav-header">
    <div class="container">
      <!-- 左侧 文字 -->
      <div class="n-logo">
        <h2>艺光国际文化传媒（北京）有限公司</h2>
      </div>

      <!-- 右侧 -->
      <div class="n-r">
        <!-- 导航列表 -->
        <div class="n-list">
          <a class="active" href="">首页</a>
          <!-- <a href="/#/xy">客服中心</a> -->
          <a href="/#/ac1">有偿资讯</a>
          <a href="/#/info">关于我们</a>
        </div>

        <!-- 登录注册 -->
        <div class="n-login">
          <!-- 登录注册 -->
          <span class="n-login" @click="goLogin()" v-show="this.LoggedShow">
          <i class="iconfont icon-denglu"></i>
          登录
        </span>

        <span class="n-login" @click="goRegister()" v-show="this.LoggedShow">
          <i class="iconfont icon-zhuce"></i>
          注册
        </span>
          <!-- <div class="n-login2" @click="goLogin()" v-show="this.LoggedShow">
            登录/注册
          </div> -->

          <!-- 用户名 -->
          <span
            class="n-info"
            v-show="!this.LoggedShow"
            v-text="this.nv_user.Username"
            ><i class="iconfont icon-denglu"></i
          ></span>

          <!-- 退出登录 -->
          <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
            <i class="iconfont icon-tuichudenglu"></i>
            退出登录
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },

    // 跳转最新文章
    goAc1() {
      this.$router.push("/ac1");
    },
    // 跳转行业资讯
    goAc2() {
      this.$router.push("/ac2");
    },
    // 跳转独加新闻
    goAc3() {
      this.$router.push("/ac3");
    },
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议

    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-header {
  width: 100%;
  height: 80px;
  background: #fff;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  .container {
    height: 100%;
    @include flex();
    // 左侧 logo
    .n-logo {
      height: 100%;
      line-height: 80px;
      h2 {
        font-size: 18px;
      }
    }

    // 右侧
    .n-r {
      height: 100%;
      display: flex;
      align-items: center;
      // 导航
      .n-list {
        display: flex;
        a {
          height: 30px;
          font-size: 16px;
          // background: pink;
          margin: 0 20px;
          display: inline-block;
          line-height: 30px;
          border-bottom: 2px solid #fff;
          transition: all 0.3s;
          color: #333;
          &.active {
            color: $colorZ;
            // border-bottom: 2px solid $colorZ;
          }
          // width: 80px;
          &:hover {
            color: $colorZ;
            // border-bottom: 2px solid $colorZ;
          }
        }
      }

      // 登录注册
      .n-login {
        .n-login2 {
          width: 80px;
          line-height: 30px;
          text-align: center;
          height: 30px;
          font-size: 14px;
          border: 1px solid $colorZ;
          // color: #333;
          transition: all 0.3s;
          cursor: pointer;
          color: #fff;
          background-color: $colorZ;
        }
        span {
          font-size: 16px;
          margin: 0 15px;
          color: $colorZ;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
