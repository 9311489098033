<template>
  <div class="info">
    <!-- 导航栏 -->

    <!-- 定宽 -->
    <div class="container">
      <!-- 左侧 导航 -->
      <div class="info-1">
        <ul>
          <li class="active">关于我们</li>
          <li @click="goXy()">用户协议</li>
        </ul>
      </div>
      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>关于我们</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <!-- banenr -->
        <div class="i2-banner"></div>
        <div class="i2-con">
          <h3>公司名称：艺光国际文化传媒（北京）有限公司</h3>
          <h3>电话：18510664759</h3>
          <h3>邮箱：740704251@qq.com</h3>
          <h3>地址： 北京市朝阳区广顺北大街33号院1号楼10层1单元1101室9151号</h3>

          <p>
            简介：艺光国际文化传媒(北京)有限公司成立于2017年06月13日，经营范围包括组织文化艺术交流活动（不含演出）；教育咨询（不含出国留学咨询及中介服务）；企业策划；企业管理咨询；文艺创作；承办展览展示活动；销售乐器；租赁机械设备、乐器；互联网信息服务；经营电信业务；出版物零售；广播电视节目制作；人力资源服务；文艺表演。（市场主体依法自主选择经营项目，开展经营活动；互联网信息服务、经营电信业务、出版物零售、广播电视节目制作、人力资源服务、文艺表演以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  components: {},
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "艺光国际文化传媒（北京）有限公司";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  width: 100%;
  background-color: #f0f0f0;
  // banner
  .i-banner {
    width: 100%;
    height: 250px;
    background-color: pink;
    margin-bottom: 120px;
  }

  // 定宽布局
  .container {
    @include flex();
    height: 800px;
    margin-bottom: 30px;
    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;
      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;
        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;
          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }
    // 右侧
    .info-2 {
      width: 70%;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      box-sizing: border-box;
      text-align: center;
      h3 {
        font-size: 24px;
      }
      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        p {
          font-size: 13px;
          line-height: 3.2em;
          color: #4e4b4b;
          margin: 15px auto;
          padding: 10px;
          box-sizing: border-box;
          text-align: left;
        }
        h3 {
          font-size: 18px;
          margin: 15px auto;
        }
      }
    }
  }
}
</style>
